import { useEffect, useState } from 'react';
import { Box, Button, Grommet, Header, RadioButtonGroup, Text } from 'grommet';
import VolatilityTable from './Components/VolatilityTable';
import { OptionType } from './Components/VolatilityTable/option.interface';
import { Moon, Sun } from 'grommet-icons';
import { theme } from './theme';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [optionType, setOptionType] = useState("C");

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    setDarkMode(localTheme === "dark");
  }, []);

  const saveSelectedTheme = (theme: string) => {
    localStorage.setItem("theme", theme);
  }

  return (
    <Grommet theme={theme} themeMode={darkMode ? "dark" : "light"} full>
      <Box fill={true}>
        <Header pad="small">
          ETO Volatilty At The Money
            <Box justify="end" direction="row" gap="medium">
            <Button icon={darkMode ? <Sun /> : <Moon />} onClick={() => {
              const selected = !darkMode;
              saveSelectedTheme(selected ? "dark" : "light");
              setDarkMode(selected);
            }} />
          </Box>
        </Header>
        <Box direction="row" gap="medium" pad="small" align="center" overflow="hidden">
          <Text>Option Type</Text>
          <RadioButtonGroup
            name="option-type"
            direction="row"
            gap="none"
            options={['C', 'P']}
            value={optionType}
            onChange={event => setOptionType(event.target.value)}
          >
            {(option: string, { checked, hover }: { checked: boolean, hover: boolean }) => {
              const type = option === 'C' ? "Call" : "Put";
              let background;
              if (checked) {
                background = 'brand';
              }
              else if (hover) {
                background = 'light-4';
              }
              else {
                background = 'light-2';
              }
              return (
                <Box background={background} pad="xsmall">
                  {type}
                </Box>
              );
            }}
          </RadioButtonGroup>
        </Box>
        <Box flex={true} overflow={{ horizontal: "auto" }}>
          <VolatilityTable type={optionType as OptionType} />
        </Box>
      </Box>
    </Grommet>
  );
}

export default App;
