import { useEffect, useRef, useState } from "react";
import { Box, DataTable, Spinner, Text } from "grommet";
import { Option } from "./option.interface";
import { groupBy, monthNames, scaledPercent } from "../../common";

const VolatilityTable = (props: Option) => {
  const initialSort: { property: string; direction: 'asc' | 'desc' } = {
    property: 'code',
    direction: 'asc'
  };

  const [sort, setSort] = useState(initialSort);
  const [vol, setVol]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  // Save the lowest and highest vol so we can normalise the percentages later
  const lowestVol = useRef(100);
  const highestVol = useRef(0);

  useEffect(() => {
    async function getVol() {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/vol?optionType=${props.type}`);
        const data = await response.json();
        for (const row of data) {
          if (row.vol < lowestVol.current) {
            lowestVol.current = row.vol;
          }

          if (row.vol > highestVol.current) {
            highestVol.current = row.vol;
          }
        }

        setVol(data);
      } catch (exception) { }
      finally {
        setLoading(false);
      }
    }
    getVol();
  }, [props.type]);

  const formattedVol: any[] = [];
  for (const entry of vol) {
    const date = new Date(entry.expiry).getTime();
    formattedVol.push({
      code: entry.code,
      [date]: +(entry.vol)
    });
  }

  const groupedVol = groupBy(formattedVol, "code");
  const codes = Object.keys(groupedVol);
  const flattenedGroups = [];
  for (const code of codes) {
    flattenedGroups.push(Object.assign.apply(Object, groupedVol[code]));
  }

  const allDates = [...new Set(vol.map((x: { expiry: string; }) => x.expiry).sort())];
  const dates = allDates.map((x: any) => {
    const date = new Date(x);
    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  });

  for (const code of codes) {
    const current = flattenedGroups.find(x => x.code === code);
    const datesAsTime = dates.map(x => new Date(x).getTime());
    for (const time of datesAsTime) {
      if (!current[time]) {
        current[time] = null;
      }
    }
  }

  const dateColumns = dates.map(x => {
    const date = new Date(x).getTime().toString();
    return {
      property: date,
      header: x,
      primary: false,
      pin: false,
      render: (datum: any) => {
        const current = datum[date];
        const colour = scaledPercent(current, lowestVol.current, highestVol.current);
        return (<Box background={`${colour}`}>{current && (current * 100).toFixed(2)}</Box>)
      }
    }
  });

  const columns = [{
    property: 'code',
    header: 'Code',
    primary: true,
    pin: true
  }].concat(dateColumns);

  // TODO: Remove secondary scroll bar
  if (loading) {
    return (
      <Box align="center" pad="large">
        <Spinner align="center" justify="center" size="medium" message="Loading" />
        <Text className="mt-2">Loading {props.type === 'C' ? "Call" : "Put"} Volatilty...</Text>
      </Box>
    )
  } else {
    return (
      <DataTable
        columns={columns}
        data={flattenedGroups}
        sort={sort}
        onSort={setSort}
        step={100}
        fill
        pin
      />
    )
  }
}
export default VolatilityTable;