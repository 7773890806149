export const groupBy = (items: any, key: string) => items.reduce(
  (result: any, item: any) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const perc2color = (value: number) => {
  var r,
    g,
    b = 0;
  if (value < 50) {
    r = 255;
    g = Math.round(5.1 * value);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * value);
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  return "#" + ("000000" + h.toString(16)).slice(-6) + "80";
};

export const scaledPercent = (value: number, lowest: number, highest: number) => {
  if (value === null || value === 0) {
    return null;
  }

  const scaled = ((value - lowest) / (highest - lowest)) * 100;
  return perc2color(100 - scaled);
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];